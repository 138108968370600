<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        v-on="on"
        :color="isPublished ? 'green' : 'orange'"
        @click="$emit('click', isPublished ? 0 : 1)"
      >
        <v-icon v-if="isPublished">mdi-eye</v-icon>
        <v-icon v-if="!isPublished">mdi-eye-off</v-icon>
      </v-btn>
    </template>
    <span>
      {{ isPublished ? 'Click here to unpublish' : 'Click here to publish' }}
    </span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'PublishToggle',
  props: {
    isPublished: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style></style>
